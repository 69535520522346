import React, { FC, useEffect, useRef, useState } from 'react';
import MainTemplate from '../components/MainTemplate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { PageProps, Pages } from '../App';
import ReactGA from 'react-ga4';
import { GAEvents } from '../gtag/constants';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material';

const RESEND_COOLDOWN_SECONDS = 10;

const Started: FC<PageProps> = ({ setPage, userData, startExperience }) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const intervalRef = useRef<number | undefined>();

  const restartHandler = async () => {
    setPage(Pages.Landing);
    ReactGA.event(GAEvents.StartOverStartPage);
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    startCountdown();

    ReactGA.event(GAEvents.Resend);

    setIsLoading(true);
    const res2 = await startExperience(true);
    setIsLoading(false);

    if (!res2) return;

    if (res2 && 'status' in res2 && res2.status === 200) {
      setPage(Pages.Started);
    } else {
      setPage(Pages.Error);
      throw 'Could not resend';
    }
  };

  const startCountdown = () => {
    setResendCooldown(RESEND_COOLDOWN_SECONDS);

    intervalRef.current = window.setInterval(() => {
      setResendCooldown((cooldown) => {
        if (cooldown === 1) clearInterval(intervalRef.current);
        return cooldown - 1;
      });
    }, 1000);
  };

  // Clean up interval if the component unmounts before it gets to 0
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <MainTemplate>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          textTransform: 'none'
        }}
        onClick={restartHandler}
      >
        Start over
      </Button>
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          Hello!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            maxWidth: '250px',
            margin: '0 auto 40px',
            lineHeight: '1.4'
          }}
        >
          A link has been sent to your {userData.phone ? 'phone' : 'email'}. The
          Link will direct you to the app store to download Mobile Fit.
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          Haven&apos;t received{' '}
          {userData.phone ? 'the text message' : 'an email'} yet?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <form onSubmit={submitHandler}>
            <LoadingButton
              type="submit"
              sx={{ marginBottom: 2, background: '#fff' }}
              color="inherit"
              variant="outlined"
              loading={isLoading}
              disabled={resendCooldown > 0}
            >
              Resend {resendCooldown > 0 ? resendCooldown : ''}
            </LoadingButton>
          </form>
          {userData.phone && (
            <Typography variant="subtitle2">SMS charges may apply</Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          margin: '0 0 -40px',
          backgroundColor: '#40445c',
          padding: '16px',
          textAlign: 'center',
          color: '#fff',
          [theme.breakpoints.up('sm')]: {
            margin: '0 -60px -40px'
          }
        }}
      >
        <Typography variant="subtitle1" mb={0.5}>
          Important: Use the Same ID!
        </Typography>
        <Typography variant="subtitle2">
          Please log in with the same email or phone number you used to
          register. Using a different ID may prevent access to your account.
        </Typography>
      </Box>
    </MainTemplate>
  );
};

export default Started;
